<style lang="scss" scoped>
@use "assets/scss/default.scss";
</style>

<template>
  <div class="h-screen flex flex-col">
    <!-- Header fixé en haut -->
    <Header class="flex-shrink-0 mt-3 ml-3 mr-3" />


    <!-- Navbar sur la gauche avec largeur fixe et sans agrandissement -->
    <Navbar
      :navLinks="navLinks"
      class="flex-shrink-0 ml-3 absolute cconainter bottom-0 py-4"
    />

    <!-- Conteneur principal en flex pour navbar et main -->
    <div id="wrapper" class="flex flex-1 min-h-0 absolute p-4 overflow-auto">

      <!-- Main à droite, scrollable en cas de dépassement -->
      <main class="flex-1 min-h-0 h-full overflow-visible flex flex-col">
        <slot />
      </main>
    </div>
  </div>
</template>

<script setup>
const ui = {
  base: "h-full",
  padding: "",
  constrained: ""
};

const navLinks = [
  { url: "/management", name: "Administration", icon: "isax isax-element-4" },
  { url: "/management/accounts", name: "Comptes client", icon: "isax isax-buildings4" },
  { url: "/management/orders", name: "Commandes", icon: "isax isax-box" },
  { url: "/management/config", name: "Configurations", icon: "isax isax-code-1" },
  { url: "/management/inspector", name: "Inspecteur", icon: "isax isax-box-search" },
  { url: "/management/status", name: "Statuts", icon: "isax isax-archive" },
  { url: "/management/amazon", name: "Amazon", icon: "isax isax-bag-happy" },
];
</script>

<script>


export default {
  name: "ManagementLayout"
};
</script>
